<script lang="ts" generics="Item">
	import type { EmblaCarouselType, EmblaOptionsType, EmblaPluginType } from "embla-carousel";
	import emblaCarouselSvelte from "embla-carousel-svelte";
	import type { Snippet } from "svelte";
	import type { ClassValue } from "svelte/elements";
	import { lerp } from "@core/utils/lerp.js";

	let {
		api = $bindable(),
		/* eslint-disable prefer-const */
		class: className,
		item,
		items,
		itemsContainerClass,
		options = {},
		plugins = [],
		type = "flex",
		/* eslint-enable prefer-const */
	}: {
		api?: EmblaCarouselType;
		class?: ClassValue;
		item: Snippet<[item: Item, index: number]>;
		items: Item[];
		itemsContainerClass?: ClassValue;
		options?: EmblaOptionsType;
		plugins?: EmblaPluginType[];
		type?: "flex" | "grid";
	} = $props();

	let duplicateLoopTimes = $state(1);
	function onemblaInit(event: CustomEvent<EmblaCarouselType>): void {
		api = event.detail;
		const biggestSlide = api
			.slideNodes()
			.reduceRight((prev, curr) => (curr.clientWidth > prev.clientWidth ? curr : prev));
		const possibleSlideAmount = Math.ceil(api.containerNode().clientWidth / biggestSlide.clientWidth);
		duplicateLoopTimes = Math.ceil(
			items.length <= possibleSlideAmount ? lerp(possibleSlideAmount + 1, 2, items.length / possibleSlideAmount) : 1,
		);
	}

	function isScrollable(api: EmblaCarouselType): boolean {
		return api.internalEngine().scrollSnaps.length > 1;
	}

	const duplicatedItems = $derived(
		options.loop ? Array.from({ length: duplicateLoopTimes }).flatMap(() => items) : items,
	);

	// How to use:
	// https://www.embla-carousel.com/get-started/svelte/
	// https://www.embla-carousel.com/guides/
</script>

<div
	class={["overflow-hidden", className]}
	use:emblaCarouselSvelte={{
		options: {
			duration: 20,
			inViewThreshold: 0.5,
			watchDrag: isScrollable,
			...options,
		},
		plugins,
	}}
	{onemblaInit}
>
	<div
		class={[
			"min-h-[inherit] h-full [&>*]:min-w-0 [&>*]:grow-0 [&>*]:shrink-0 [&>*]:max-w-full",
			itemsContainerClass,
			type,
		]}
		class:flex-col={type === "flex" && options.axis === "y"}
	>
		{#each duplicatedItems as it, index (index)}
			{@render item(it, index)}
		{/each}
	</div>
</div>
